<template>
  <AbstractFilter
    :on-filter="filterAuthors"
    :on-filter-reset="resetFilter"
    :button-required-permissions="requiredPermissions"
    create-new-route-name="author_new"
    filter-panel-class="filter-panel-author"
  >
    <SiteSelect
      v-model="filter.defaultSite"
      :options="sites"
      :label="$t('authorVlm.defaultSite')"
      id="filter_category"
    />
    <Input
      v-model="filter.name"
      id="filter_firstName"
      :label="$t('authorVlm.firstName')"
    />
    <Input
      v-model="filter.originName"
      id="filter_lastName"
      :label="$t('authorVlm.lastName')"
    />
    <Input
      v-model="filter.id"
      id="filter_id"
      label="Id"
    />
    <Checkbox
      v-model="filter.typePhotographer"
      id="filter_typePhotographer"
      :label="$t('authorVlm.typePhotographer')"
    />
  </AbstractFilter>
</template>

<script>

import AuthorFilterModel from '@/model/AuthorFilterModelVlm'
import AbstractFilter from '@/components/filter/AbstractFilter'
import Input from '@/components/form/inputs/Input'
import Checkbox from '@/components/form/Checkbox'
import PermissionService from '@/services/PermissionService'
import SiteSelect from '@/components/form/select/SiteSelect.vue'

export default {
  name: 'AuthorFilterVlm',
  components: {
    SiteSelect,
    AbstractFilter,
    Input,
    Checkbox
  },
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(AuthorFilterModel),
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_AUTHOR_PERMISSIONS
    }
  },
  computed: {
    sites () {
      return this.$store.getters['site/allEnabledSorted']()
    }
  },
  methods: {
    filterAuthors () {
      this.$store.commit('author/setPage', 1)
      this.$store.commit('author/setFilter', this.filter)
      this.$store.dispatch('author/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(AuthorFilterModel)
      this.$store.commit('author/setFilter', this.filter)
      this.$store.dispatch('author/fetch')
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['author/filter']
  }
}
</script>

<style lang="scss">
.filter-panel-author {
  @include bp(7) {
    grid-template-columns: 1fr 1fr;
  }
  @include bp(12) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
</style>
